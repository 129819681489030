<template>
  <div
    v-if="tab"
    id="open-tab"
    class="flex w-full sm:w-80 flex-col h-full bg-n-800 border-l border-n-700 py-3 sm:py-4"
  >
    <div v-if="!isFastMode" class="flex justify-between items-center px-3">
      <ordering-mode-selector :tab-id="tabId" />
      <tab-utils-button :tab-id="tabId" />
    </div>
    <div v-else class="flex justify-end items-center px-3 gap-4">
      <l-option-selector
        v-if="fastModeTakeaway"
        class="w-full"
        :options="[
          {
            label: $t('tabs.eat-here'),
            value: false
          },
          {
            label: $t('tabs.take-away'),
            value: true
          }
        ]"
        v-model="useFastMode().pickupType"
        @update:model-value="updatePickupType"
      />
      <l-dropdown
        v-model:open="openFastModeOptions"
        :options="[
          {
            icon: 'cart',
            label: $t('tabs.save-tab'),
            value: 'save-tab',
            onClick: () => {
              useFastMode().saveTab()
              openFastModeOptions = false
            }
          }
        ]"
        placement="bottom-end"
        :allow-remove="false"
        :search="false"
        :multiselect="false"
      >
        <l-button
          class="relative"
          size="small"
          icon="dots"
          @click="openFastModeOptions = !openFastModeOptions"
        />
      </l-dropdown>
    </div>
    <div ref="scroller" class="flex-1 mt-4 overflow-y-scroll scrolling-touch">
      <div
        v-if="isFastMode || tab.orderingMode === 'seats'"
        v-memo="[selectedSeat, seatProducts, sharedProducts]"
      >
        <l-section-side-menu
          v-memo="[selectedSeat, sharedProducts]"
          :section-name="$t('ordering.shared')"
          :section-selected="selectedSeat === null"
          :show-section-name="!isFastMode"
          :elements="sharedProducts"
          @draggable-start="startDraggable"
          @draggable-end="endDraggable"
          @selected-section="$emit('update:selectedSeat', null)"
          @on-add="event => onAdd(null, event)"
          @on-update="event => onUpdate(null, event)"
        >
          <template #default="{ item: product }">
            <tab-product
              :key="product.id"
              :product="product"
              :disable-swipe="disableSwipe"
              @selected="selectProduct(product, null, null)"
            />
          </template>
        </l-section-side-menu>
        <template v-for="(seat, index) in seatProducts" :key="index">
          <l-section-side-menu
            :section-name="`${$t('ordering.seat')} ${index + 1}`"
            :section-selected="selectedSeat === index"
            :elements="seat"
            :show-remove="true"
            @draggable-start="startDraggable"
            @draggable-end="endDraggable"
            @selected-section="$emit('update:selectedSeat', index)"
            @on-add="event => onAdd(index, event)"
            @on-update="event => onUpdate(index, event)"
            @on-remove="onRemove(index)"
          >
            <template #default="{ item: product }">
              <tab-product
                :key="product.id"
                :product="product"
                :disable-swipe="disableSwipe"
                @selected="selectProduct(product, null)"
              />
            </template>
          </l-section-side-menu>
        </template>
        <div
          v-if="!isFastMode"
          class="flex gap-2 font-body text-xs leading-4 items-center p-3 text-center text-v add-seat cursor-pointer"
          @click="addSeat(tabId)"
        >
          <l-icon name="plus" />
          <div>{{ $t('ordering.add-seat') }}</div>
        </div>
      </div>
      <div v-else v-memo="[selectedCourse, courseProducts]">
        <template v-for="course in courseProducts" :key="course.name">
          <l-section-side-menu
            :section-name="course.name"
            :section-selected="selectedCourse === course.name"
            :elements="course.products"
            @draggable-start="startDraggable"
            @selected-section="updateSelectedCourse(course.name)"
            @draggable-end="endDraggable"
            @on-add="event => onAddCourse(course.name, event)"
            @on-update="event => onUpdateCourse(course.name, event)"
          >
            <template #default="{ item: product }">
              <tab-product
                :key="getProductId(product)"
                :product="{
                  ...product,
                  comboProducts: product.shownCombo
                    ? [product.shownCombo]
                    : product.comboProducts
                }"
                :disable-swipe="disableSwipe"
                @selected="selectProduct(product, null, course.name)"
              />
            </template>
          </l-section-side-menu>
        </template>
        <div class="h-16" />
      </div>
      <div
        v-if="isFastMode && sharedProducts.length === 0"
        class="h-full flex items-center justify-center p-4"
      >
        <empty-case
          :type="cartImage"
          enforced-translation-key="ordering.empty-cart"
        />
      </div>
    </div>
    <div class="flex flex-col gap-5">
      <balance :tab-id="tabId" class="z-10" />
      <tab-actions :tab-id="tabId" :editing="true" class="px-3" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  ref,
  toRef,
  useTemplateRef
} from 'vue'
import { SortableEvent } from 'vue-draggable-plus'
import { useRoute } from 'vue-router'

import {
  EmptyCase,
  LButton,
  LDropdown,
  LIcon,
  LOptionSelector,
  type OptionValue
} from '@last/core-ui/paprika'

import cartImage from '@/assets/cart.svg?raw'
import Balance from '@/components/core/Balance.vue'
import LSectionSideMenu from '@/components/core/LSectionSideMenu.vue'
import TabProduct from '@/components/ordering/TabProduct.vue'
import TabActions from '@/components/tabs/TabActions.vue'
import { useTabs } from '@/composables/useTabs'
import { useConfigStore } from '@/store/config'
import { useFastMode } from '@/store/fast-mode'
import { useTabsStore } from '@/store/tabs'
import type { Product } from '@/types'

import OrderingModeSelector from './OrderingModeSelector.vue'
import TabUtilsButton from './TabUtilsButton.vue'

const props = defineProps<{
  tabId: string
  selectedSeat: number | null
  selectedCourse: string
  selectedProductId?: string
}>()

const emit = defineEmits<{
  'update:selectedSeat': [number | null]
  'update:selectedCourse': [string | null]
  selectedProduct: [Product]
  selectedComboProduct: [{ product: Product; comboProduct: Product }]
}>()

const tabsStore = useTabsStore()
const configStore = useConfigStore()

const { seatProducts, sharedProducts, courseProducts, tab } = useTabs(
  toRef(() => props.tabId)
)

const { moveProduct, removeSeat, updateCourse } = tabsStore
const { addSeat } = tabsStore

const route = useRoute()
const isFastMode = computed(() => {
  return route.name === 'fastMode'
})

const scroller = useTemplateRef('scroller')
const disableSwipe = ref(false)
const openFastModeOptions = ref(false)

const fastModeTakeaway = computed(() => {
  return isFastMode.value && !!configStore.config.fastModeTakeaway
})

function updateSelectedCourse(course: string) {
  emit('update:selectedCourse', course === props.selectedCourse ? null : course)
}

function onRemove(index: number) {
  removeSeat({ tabId: props.tabId, selectedSeatIndex: index })
  emit('update:selectedSeat', null)
}

function selectProduct(
  product: Product,
  seat: number | null,
  course: string | null
) {
  if (product.comboProducts) {
    if (course) product.shownCombo.course = course
    emit('selectedComboProduct', {
      product,
      comboProduct: product.shownCombo
    })
  } else {
    emit('selectedProduct', product)
  }
  if (seat) emit('update:selectedSeat', seat)
  if (course) emit('update:selectedCourse', course)
}

function onUpdate(seat: number | null, event: SortableEvent) {
  const productId = event.item.id
  const position = event.newIndex
  moveProduct({ tabId: props.tabId, seat, position, productId })
}

function onAdd(seat: number | null, event: SortableEvent) {
  const productId = event.item.id
  const position = event.newIndex
  moveProduct({ tabId: props.tabId, seat, position, productId })
}

function onUpdateCourse(course: string, event: SortableEvent) {
  const comboId = event.item.getAttribute('combo-id')
  const productId = comboId || event.item.id
  updateCourse({
    course,
    productId: productId,
    comboProductId: comboId ? event.item.id : null
  })
}

function onAddCourse(course: string, event: SortableEvent) {
  const comboId = event.item.getAttribute('combo-id')
  const productId = comboId || event.item.id
  updateCourse({
    course,
    productId: productId,
    comboProductId: comboId ? event.item.id : null
  })
}

function getProductId(product: Product) {
  return product.comboProducts ? product.shownCombo.id : product.id
}

function startDraggable() {
  disableSwipe.value = true
}

async function endDraggable() {
  disableSwipe.value = false
  const scrollPosition = scroller.value?.scrollTop || 0
  await nextTick()
  scroller.value!.scrollTop = scrollPosition
}

function updatePickupType(value: OptionValue) {
  tabsStore.changePickupType({
    tabId: props.tabId,
    pickupType: value === true ? 'takeAway' : undefined
  })
}
</script>
